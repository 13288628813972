import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { accountCardsAtom } from 'shared/recoil/atoms/accountCards';

import type { ReactNode } from 'react';

const AccountNameWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
`;

interface Props {
  connectionId: string;
  children: ReactNode;
}

export const AccountName = ({ connectionId, children }: Props) => {
  const [accountCards] = useRecoilState(accountCardsAtom);
  return (
    <AccountNameWrapper
      onClick={() => {
        const element = accountCards?.[connectionId];
        element?.current.click();
        setTimeout(() => {
          element?.current.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }, 0);
      }}
    >
      {children}
    </AccountNameWrapper>
  );
};
