import { Button, Modal, Switch } from 'antd';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { Input } from 'shared/components/Atoms/Input';
import { hideZeroTotalAccountsAtom } from 'shared/recoil/atoms/accounts';

import type { Control } from 'react-hook-form';

const SettingsModalWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
    border-radius: 30px;
  }

  .ant-modal-footer {
    display: flex;
    margin-top: 24px;
  }
`;

const SaveButton = styled(Button)`
  &.ant-btn.ant-btn-sm {
    padding: 12px 16px 12px 16px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 20px;
    height: 46px;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 12px;
`;

const SettingItemWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
`;

interface Props {
  onCancel: () => void;
  open: boolean;
  magicNumber: number;
  control: Control<{
    showAllTokensLimits: string | number;
  }>;
}

export const SettingsModal = ({ onCancel, open, magicNumber, control }: Props) => {
  const [hideZeroTotalAccounts, setHideZeroTotalAccounts] =
    useRecoilState(hideZeroTotalAccountsAtom);
  const handleSave = () => {
    localStorage.setItem('showAllTokensLimits', magicNumber.toString());
    onCancel();
  };

  useEffect(() => {
    setHideZeroTotalAccounts(!!localStorage.getItem('hideZeroTotalAccounts') || false);
  }, []);

  const handleHideZeroTotalAccounts = (checked: boolean) => {
    localStorage.setItem('hideZeroTotalAccounts', checked.toString());
    setHideZeroTotalAccounts(checked);
  };

  return (
    <SettingsModalWrapper
      width={400}
      destroyOnClose={true}
      title={<div style={{ fontSize: 24 }}>Settings</div>}
      open={open}
      onCancel={onCancel}
      footer={[
        <SaveButton disabled={magicNumber < 0} onClick={handleSave} key="save" type="primary">
          Save changes
        </SaveButton>,
      ]}
    >
      {open ? (
        <>
          <Description>
            Change the price below which you want to filter tokens in the table.
          </Description>
          <Input
            label="Show all tokens more than $"
            name="showAllTokensLimits"
            control={control}
            type="number"
            min={0}
          />
          <SettingItemWrapper>
            <span>Hide accounts with total 0</span>
            <Switch checked={hideZeroTotalAccounts} onChange={handleHideZeroTotalAccounts} />
          </SettingItemWrapper>
        </>
      ) : null}
    </SettingsModalWrapper>
  );
};
