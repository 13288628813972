import { Card, Divider, Table } from 'antd';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import {
  accountsColumns,
  accountsProtocolColumns,
  assetsColumns,
  protocolColumns,
} from 'shared/components/Assets/AssetsSummaryTable/columns';
import { TotalAssets } from 'shared/components/Assets/AssetsSummaryTable/TotalAssets';
import { themeAtom } from 'shared/recoil/atoms/theme';

const NestedTable = styled(Table)`
  & .ant-table {
    margin: 0 !important;
  }
`;

interface Props {
  isCalculating: boolean;
  filteredTokens: [];
  filteredProtocols: [];
  isProtocols: boolean;
}

export const AccountSummaryTableContent = ({
  isCalculating,
  filteredTokens,
  isProtocols,
  filteredProtocols,
  currency,
}: Props) => {
  const [appTheme, setTheme] = useRecoilState(themeAtom);
  return (
    <>
      <Table
        scroll={{ x: 'scrollable' }}
        tableLayout="auto"
        loading={isCalculating}
        size="small"
        columns={assetsColumns({ currency, isDarkTheme: appTheme === 'dark' })}
        pagination={false}
        dataSource={filteredTokens}
        expandable={{
          expandedRowRender: ({ accountsMap }) =>
            accountsMap?.length ? (
              <Card style={{ borderRadius: 0 }} type="inner">
                <NestedTable
                  size="small"
                  rowKey="id"
                  columns={accountsColumns}
                  dataSource={accountsMap
                    .map(({ key, accountName, ...rest }, index) => ({
                      id: `${key}-${index}-${accountName}`,
                      accountName,
                      ...rest,
                    }))
                    .sort((a, b) => b.totalUsd - a.totalUsd)}
                  pagination={false}
                />
              </Card>
            ) : null,
        }}
        rowKey="coingecko_id"
      />
      {isProtocols ? (
        <>
          <Divider orientation="left">
            Protocols (<TotalAssets type="protocols" />)
          </Divider>
          <Table
            scroll={{ x: 'scrollable' }}
            tableLayout="auto"
            size="small"
            columns={protocolColumns({ currency })}
            pagination={false}
            dataSource={filteredProtocols}
            expandable={{
              expandedRowRender: ({ accountsMap }) => (
                <Card style={{ borderRadius: 0 }}>
                  <NestedTable
                    rowKey="key"
                    columns={accountsProtocolColumns}
                    dataSource={Object.entries(accountsMap).map(([id, value]) => ({
                      id,
                      ...value,
                    }))}
                    pagination={false}
                  />
                </Card>
              ),
            }}
            rowKey="protocol"
          />
        </>
      ) : null}
    </>
  );
};
