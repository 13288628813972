import { Divider, Space, Switch, Table, Typography } from 'antd';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { ExchangesTypes } from 'shared/components/Exchages/Exchanges.interfaces';
import { ProtocolsTable } from 'shared/components/Protocols/ProtocolsTable';
import { TransactionsTable } from 'shared/components/Transactions/TransactionsTable';
import { formatUsd } from 'shared/helpers/formatUsd';
import {
  cryptoTickersAtom,
  currencyAtom,
  selectedCurrencyAtom,
} from 'shared/recoil/atoms/currency';
import { themeAtom } from 'shared/recoil/atoms/theme';
import {
  calculatePercentageChangeInCurrency,
  calculateRelativeChange,
  coinGeckoIds,
  convertCryptoPrice,
} from 'shared/utils/currencyConverter';

import { getTokenColumns } from './columns';

interface Props {
  accountId: string;
  showNetworks: boolean;
  protocolTotal: number;
  accountType: string;
  assets: any;
}

export const PositionsTable = ({ positions }) => {
  const [showAll, setShowAll] = useState(false);
  const MAGIC_NUMBER = localStorage.getItem('showAllTokensLimits') || 10;
  return (
    <>
      <Table
        scroll={{ x: 'scrollable' }}
        tableLayout="auto"
        rowKey="id"
        size="small"
        columns={[
          // {
          //   title: 'Coingecko Id',
          //   dataIndex: 'coingecko_id',
          //   key: 'coingecko_id',
          //   render: (_, { coingecko_id }) => coingecko_id,
          // },
          {
            title: 'Instrument',
            dataIndex: 'instrument',
            key: 'instrument',
          },
          {
            title: 'Leverage',
            dataIndex: 'leverage',
            key: 'leverage',
            render: (_, { leverage, marginMode }) => (
              <span style={{ textTransform: 'capitalize' }}>
                {marginMode ? `${marginMode} x${leverage}` : `x${leverage}`}
              </span>
            ),
          },
          {
            title: 'Side',
            dataIndex: 'side',
            key: 'side',
          },
          {
            title: 'Volume',
            key: 'volume',
            render: (_, { quantity, amount, instrument, currency }) =>
              `${Number(amount).toFixed(8)} ${currency} / ${quantity} ${instrument}`,
          },
          {
            title: 'Unrealized PnL',
            dataIndex: 'unpnl',
            key: 'unpnl',
            render: value => (
              <Typography.Text type={Number(value) > 0 ? 'success' : 'danger'}>
                {Number(value).toFixed(8)}
              </Typography.Text>
            ),
          },
          {
            title: 'Total USD',
            dataIndex: 'totalUsd',
            key: 'totalUsd',
            align: 'left',
            render: (value: number) => formatUsd(value),
            responsive: ['sm'],
            sortOrder: 'descend',
            sorter: ({ totalUsd }: any, b: any) => totalUsd - b.totalUsd,
          },
        ]}
        pagination={false}
        dataSource={positions}
      />
      <Space align="center" style={{ marginBottom: 16, marginTop: 16 }}>
        {`Show all tokens < $${MAGIC_NUMBER}`}:
        <Switch checked={showAll} onChange={setShowAll} />
      </Space>
    </>
  );
};

export const AssetsTable = ({
  accountId,
  showNetworks,
  protocolTotal,
  accountType,
  assets,
}: Props) => {
  const [appTheme, setTheme] = useRecoilState(themeAtom);
  const [cryptoTickerList, setCryptoTickers] = useRecoilState(cryptoTickersAtom);
  const [selectedCurrency, setSelectedCurrency] = useRecoilState(selectedCurrencyAtom);
  const [exchangeRates, setCurrency] = useRecoilState(currencyAtom);
  const [showAll, setShowAll] = useState(false);
  const [showUnverifiedTokens, setShowUnverifiedTokens] = useState(true);

  const { tokens = [], protocols = [] } = assets || {};

  const slice = [tokens, protocols];

  const MAGIC_NUMBER = localStorage.getItem('showAllTokensLimits') || 10;

  const [visibleTokens, visibleProtocols] = slice.map(arr =>
    arr.filter(({ totalUsd = 0 }) => Number(totalUsd) > +MAGIC_NUMBER),
  );

  const [tokensData, protocolsData] = showAll ? slice : [visibleTokens, visibleProtocols];

  const preparedTokensData = tokensData
    .filter(({ is_verified }) => showUnverifiedTokens || is_verified)
    .map(({ token, priceUsd, amount, totalUsd, day_change_percent_usd, ...rest }) => {
      const price = convertCryptoPrice({
        cryptoToUSD: priceUsd,
        exchangeRates,
        targetCurrency: selectedCurrency,
        cryptoRates: cryptoTickerList,
      }).value;

      const targetRate = exchangeRates.find(rate => {
        return selectedCurrency === rate.currency;
      });

      const targetCryptoRate = cryptoTickerList.find(rate => {
        return selectedCurrency === coinGeckoIds[rate.name];
      });

      return {
        ...rest,
        token,
        priceUsd: price,
        totalUsd: price * amount,
        amount,
        day_change_percent_usd: targetRate
          ? calculatePercentageChangeInCurrency({
              cryptoPriceTodayUSD: priceUsd,
              percentageChangeUSD: day_change_percent_usd,
              rateToday: targetRate.value,
              rateYesterday: targetRate.prevDayValue,
            })
          : calculateRelativeChange(
              {
                priceTodayUSD: priceUsd,
                percentageChangeUSD: day_change_percent_usd,
              },
              {
                priceTodayUSD: targetCryptoRate?.price,
                percentageChangeUSD: targetCryptoRate?.change_24h,
              },
            ),
      };
    });

  return (
    <>
      <Table
        scroll={{ x: 'scrollable' }}
        tableLayout="auto"
        rowKey="id"
        size="small"
        columns={getTokenColumns(
          tokens,
          showNetworks,
          accountType,
          accountId,
          selectedCurrency,
          appTheme === 'dark',
        )}
        pagination={false}
        dataSource={preparedTokensData}
        expandable={{
          expandedRowRender:
            accountType === ExchangesTypes.LooseWallet
              ? ({ token, name }) => (
                  <TransactionsTable token={{ id: token, name }} accountId={accountId} />
                )
              : undefined,
        }}
        // scroll={{ y: 300 }}
      />
      {protocolsData.length ? (
        <>
          <Divider orientation="left">Protocols ({formatUsd(protocolTotal)})</Divider>

          <ProtocolsTable protocols={protocolsData || []} />
        </>
      ) : null}

      <div>
        <Space align="center" style={{ marginBottom: 16, marginTop: 16 }}>
          {`Show all tokens < $${MAGIC_NUMBER} (${
            protocols?.length - visibleProtocols.length + tokens?.length - visibleTokens?.length
          })`}
          <Switch checked={showAll} onChange={setShowAll} />
        </Space>
        <Space align="center" style={{ marginBottom: 16, marginTop: 16 }}>
          Show unverified tokens
          <Switch checked={showUnverifiedTokens} onChange={setShowUnverifiedTokens} />
        </Space>
      </div>
    </>
  );
};
