import { Typography } from 'antd';
import { useRecoilValue } from 'recoil';

import { summaryAssetsTotal } from 'shared/recoil/selectors/summaryAssets';

import type { FunctionComponent } from 'react';

export const TotalAssets: FunctionComponent<{
  type: 'tokens' | 'protocols' | 'summary';
  children: string;
}> = ({ type, children }) => {
  const { total } = useRecoilValue(summaryAssetsTotal(type));
  return (
    <Typography.Text
      style={{
        fontSize: '17px',
        color: '#0D0D0D80',
        fontWeight: 300,
        fontFamily: 'Cabin, sans-serif',
      }}
    >
      {children}
      <Typography.Text style={{ fontWeight: '500' }}>{total}</Typography.Text>
    </Typography.Text>
  );
};
