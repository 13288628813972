import { AreaChartOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { DayChangeCell } from 'shared/components/Assets/AssetsTable/DayChangeCell';
import { ExchangesTypes } from 'shared/components/Exchages/Exchanges.interfaces';
import { networksMap } from 'shared/components/Networks/Networks.constants';
import { TokenCell } from 'shared/components/Tokens/TokenCell';
import { AddTransactionCell } from 'shared/components/Transactions/AddTransactionCell';

import type { ColumnsType } from 'antd/es/table';
import type { AccountAsset } from 'shared/components/Accounts/Accounts.interfaces';

export const CoingeckoLink = styled.a`
  display: flex;
  justify-content: center;
  font-size: 20px;
`;
export const getTokenColumns = (
  tokens: AccountAsset[],
  showNetwork: boolean,
  accountType: string,
  accountId: string,
  currency: string,
  isDarkTheme: boolean,
): ColumnsType<AccountAsset> => {
  const columns = [
    {
      title: 'Token',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,

      sorter: ({ name }, b) => {
        if (name < b.name) return -1;
        if (name > b.name) return 1;
        return 0;
      },
      ...(showNetwork
        ? {
            filters: [...new Set(tokens.map(({ network }) => network))].map(n => ({
              text: networksMap[n as string]?.name || n,
              value: n,
            })),
            onFilter: (filterValue: any, { network }: AccountAsset) => filterValue === network,
          }
        : {}),
      render: (_: any, { token, name, logo_url, network, coingecko_id, address }: AccountAsset) => (
        <TokenCell
          token={token}
          name={name}
          logo_url={logo_url}
          network={network}
          coingeckoId={coingecko_id}
          address={address}
        />
      ),
    } as any,
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (_, { amount = 0 }) => Number(amount).toFixed(4),
      sorter: ({ amount }, b) => amount - b.amount,
      ellipsis: true,
      width: 150,
      responsive: ['sm'],
    },
    {
      title: `Price ${currency}`,
      dataIndex: 'priceUsd',
      key: 'priceUsd',
      align: 'right',
      render: (_, { priceUsd = 0 }) => parseFloat((+priceUsd).toFixed(2)).toLocaleString(),
      sorter: ({ priceUsd }, b) => priceUsd - b.priceUsd,
      responsive: ['sm'],
      width: 150,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: (_, { totalUsd }) =>
        `${parseFloat(totalUsd.toFixed(2)).toLocaleString()} ${currency}`,
      defaultSortOrder: 'descend',
      width: 150,
      sorter: ({ totalUsd }, b) => totalUsd - b.totalUsd,
    },
    {
      title: 'Changes (24h)',
      key: 'day_change_percent_usd',
      align: 'right',
      sorter: ({ day_change_percent_usd }, b) => day_change_percent_usd - b.day_change_percent_usd,
      render: (_: any, { day_change_percent_usd }: AccountAsset) =>
        day_change_percent_usd ? (
          <DayChangeCell percent={day_change_percent_usd.toFixed(2)} />
        ) : null,
    },
    {
      width: '100px',
      title: 'Project info',
      key: 'projectInfo',
      align: 'right',
      responsive: ['sm'],
      render: (_: any, { coingecko_id, day_change_percent_usd }: AccountAsset) =>
        (day_change_percent_usd && coingecko_id && !coingecko_id?.includes('custom-id-')) ||
        coingecko_id?.includes('usd-coin') ||
        coingecko_id?.includes('tether') ? (
          <CoingeckoLink
            target="_blank"
            href={`https://www.coingecko.com/en/coins/${coingecko_id}`}
          >
            <AreaChartOutlined style={{ color: isDarkTheme ? '#728faa' : '' }} />
          </CoingeckoLink>
        ) : null,
    },
  ] as ColumnsType<AccountAsset>;

  if (accountType === ExchangesTypes.LooseWallet) {
    return [
      ...columns,
      {
        title: 'Edit',
        key: 'addToken',
        fixed: 'right',
        align: 'center',
        width: 60,
        render: (_, transaction) => {
          return <AddTransactionCell token={transaction.token} accountId={accountId} />;
        },
      },
    ];
  }

  // temporary only for 3c accounts

  return columns;
};
