import { FileExcelTwoTone } from '@ant-design/icons';
import { Input, Space, Switch, Table, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useRecoilState } from 'recoil';

import {
  DateRangePickerStyled,
  getUrl,
  NetworkList,
  NetworksTitle,
  NetworksWrapper,
  TransactionActions,
  TransactionsActions,
} from 'shared/components/Accounts/Transactions/AccountTransactions';
import {
  TransactionAsset,
  TransactionAssetWrapper,
  UsdTotal,
} from 'shared/components/Accounts/Transactions/components/Transaction';
import { PROTOCOLS_TX_MAP } from 'shared/components/Accounts/Transactions/consts';
import { getColor } from 'shared/components/Accounts/Transactions/helpers';
import {
  formatHash,
  TransactionFilterButton,
  TransactionFiltersWrapper,
  TransactionHashWrapper,
} from 'shared/pages/transactions/transactions';
import { TransactionsSummaryAtom } from 'shared/recoil/atoms/transactions';
import { downloadCSV, transactionsToCsv } from 'shared/utils/toCsv';

export const SummaryTransactions = () => {
  const [transactionTypeFilter, setTransactionTypeFilter] = useState('all');
  const [inputValue, setInputValue] = useState('');
  const [filterScam, setFilterScam] = useState(true);
  const [summaryTransactions] = useRecoilState(TransactionsSummaryAtom);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const summaryTransactionList = Object.values(summaryTransactions)
    .flat()
    .filter(({ sign }) => {
      if (transactionTypeFilter === 'all') {
        return true;
      }

      if (transactionTypeFilter === 'buy') {
        return sign === '+';
      }

      if (transactionTypeFilter === 'sell') {
        return sign === '-';
      }
    })
    .filter(({ txHash, fromAddress, toAddress, symbol }) => {
      const lowerCasedInputValue = inputValue.toLowerCase();

      if (inputValue) {
        return (
          txHash?.toLowerCase()?.includes(lowerCasedInputValue) ||
          fromAddress?.toLowerCase()?.includes(lowerCasedInputValue) ||
          toAddress?.toLowerCase()?.includes(lowerCasedInputValue) ||
          symbol?.toLowerCase()?.includes(lowerCasedInputValue)
        );
      }
      return true;
    });
  const formattedTransactions = Object.values(
    summaryTransactionList.reduce((acc, tx) => {
      if (acc[tx.hash + tx.address]) {
        const txAction = (
          PROTOCOLS_TX_MAP?.[`${tx.sign}${tx.methodHash}`] ||
          PROTOCOLS_TX_MAP?.[`${tx.methodHash}_${tx.toAddress}`] ||
          PROTOCOLS_TX_MAP?.[`${tx.methodHash}_${tx.fromAddress}`] ||
          PROTOCOLS_TX_MAP?.[tx.methodHash]
        )?.action;

        acc[tx.hash + tx.address] = {
          ...acc[tx.hash + tx.address],
          swappedTransaction: acc[tx.hash + tx.address]?.swappedTransaction
            ? [...acc[tx.hash + tx.address]?.swappedTransaction, tx]
            : [tx],
          transactionType: txAction || `swap(${tx?.methodHash})`,
          transactionCategory:
            (
              PROTOCOLS_TX_MAP?.[`${tx.sign}${tx.methodHash}`] ||
              PROTOCOLS_TX_MAP?.[`${tx.methodHash}_${tx.toAddress}`] ||
              PROTOCOLS_TX_MAP?.[`${tx.methodHash}_${tx.fromAddress}`] ||
              PROTOCOLS_TX_MAP?.[tx.methodHash]
            )?.category || 'swap',
        };
      } else {
        acc[tx.hash + tx.address] = tx;
      }
      return acc;
    }, {}),
  ).filter(({ scam, swappedTransaction }) => {
    if (filterScam) {
      return !scam || swappedTransaction?.find(({ scam }) => !scam);
    }
    return true;
  });

  const columns = [
    {
      title: 'Wallet',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => {
        return ('' + a.address).localeCompare(b.address);
      },
      filterSearch: true,
      filters: [...new Set(Array.from(formattedTransactions, ({ address }) => address))].map(
        address => ({
          text: address,
          value: address,
        }),
      ),
      onFilter: (value: string, record: { address: string }) => {
        return record?.address?.includes(value);
      },
      render: (_, { address, chainName }) => (
        <TransactionHashWrapper
          target="_blank"
          href={`${getUrl(chainName, '')}/address/${address}`}
        >
          <Tooltip title={address}>{formatHash(address)}</Tooltip>
        </TransactionHashWrapper>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'formattedDate',
      key: 'formattedDate',
      render: (_, { formattedDate }) => (
        <div style={{ fontSize: '11px', width: '68px' }}>{formattedDate}</div>
      ),
      sorter: (a, b) => {
        return b.blockTimestamp - a.blockTimestamp;
      },
      filterSearch: true,
      onFilter: (value: string, record: { formattedDate: string | string[] }) => {
        return record.formattedDate?.includes(value);
      },
    },
    {
      title: 'Chain',
      dataIndex: 'chainName',
      key: 'chainName',
      render: (_, { chainName }) => (
        <div style={{ fontSize: '12px', maxWidth: '100px' }}>{chainName}</div>
      ),
      // filters: [...networks].map(network => ({ text: network, value: network })),
      onFilter: (value: string, record: { chainName: string }) => {
        return record.chainName?.includes(value);
      },
    },
    {
      title: 'Hash',
      dataIndex: 'txHash',
      key: 'txHash',
      sorter: (a, b) => {
        return ('' + a.txHash).localeCompare(b.txHash);
      },
      render: (_, { txHash, chainName }) => (
        <div style={{ fontSize: '12px' }}>
          {
            <TransactionHashWrapper target="_blank" href={`${getUrl(chainName, '')}/tx/${txHash}`}>
              <Tooltip title={txHash}>{formatHash(txHash || '')}</Tooltip>
            </TransactionHashWrapper>
          }
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'transactionCategory',
      key: 'transactionCategory',
      filters: [
        ...new Set(
          Array.from(formattedTransactions, ({ transactionCategory }) => transactionCategory),
        ),
      ].map(transactionCategory => ({
        text: transactionCategory,
        value: transactionCategory,
      })),
      onFilter: (value: string, record: { transactionCategory: string }) => {
        return record?.transactionCategory?.includes(value);
      },
      sorter: (a, b) => {
        return ('' + a.transactionCategory).localeCompare(b.transactionCategory);
      },
      render: (_, { transactionCategory }) => transactionCategory,
    },
    {
      title: 'Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      filters: [
        ...new Set(Array.from(formattedTransactions, ({ transactionType }) => transactionType)),
      ].map(transactionType => ({
        text: transactionType,
        value: transactionType,
      })),
      onFilter: (value: string, record: { transactionType: string }) => {
        return record?.transactionType?.includes(value);
      },
      sorter: (a, b) => {
        return ('' + a.transactionType).localeCompare(b.transactionType);
      },
      render: (_, { transactionType }) => (
        <Tooltip title={transactionType}>
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '100px',
              height: '1.2em',
              whiteSpace: 'nowrap',
            }}
          >
            {transactionType}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Protocol',
      dataIndex: 'protocol',
      key: 'protocol',
      filters: [...new Set(Array.from(formattedTransactions, ({ protocol }) => protocol))].map(
        protocol => ({
          text: protocol,
          value: protocol,
        }),
      ),
      onFilter: (value: string, record: { protocol: string }) => {
        return record?.protocol?.includes(value);
      },
      sorter: (a, b) => {
        return ('' + a.protocol).localeCompare(b.protocol);
      },
      render: (_, { protocol, protocolImg }) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <img style={{ width: '27px' }} src={protocolImg} alt={protocol} />
          <span>{protocol}</span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'formattedStatus',
      key: 'formattedStatus',
      filters: [
        ...new Set(Array.from(formattedTransactions, ({ formattedStatus }) => formattedStatus)),
      ].map(formattedStatus => ({
        text: formattedStatus,
        value: formattedStatus,
      })),
      onFilter: (value: string, record: { formattedStatus: string }) => {
        return record?.formattedStatus?.includes(value);
      },
      render: (_, { formattedStatus }) => {
        return <div style={{ fontSize: '12px' }}>{formattedStatus}</div>;
      },
    },
    // {
    //   title: 'From',
    //   dataIndex: 'fromAddress',
    //   key: 'fromAddress',
    //   sorter: (a, b) => {
    //     return ('' + a.fromAddress).localeCompare(b.fromAddress);
    //   },
    //   render: (_, { fromAddress, chainName }) => (
    //     <TransactionHashWrapper
    //       target="_blank"
    //       href={`${getUrl(chainName, '')}/address/${fromAddress}`}
    //     >
    //       <Tooltip title={fromAddress}>{formatHash(fromAddress)}</Tooltip>
    //     </TransactionHashWrapper>
    //   ),
    // },
    // {
    //   title: 'To',
    //   dataIndex: 'toAddress',
    //   key: 'toAddress',
    //   sorter: (a, b) => {
    //     return ('' + a.toAddress).localeCompare(b.toAddress);
    //   },
    //   render: (_, { toAddress, chainName }) => (
    //     <TransactionHashWrapper
    //       target="_blank"
    //       href={`${getUrl(chainName, '')}/address/${toAddress}`}
    //     >
    //       <Tooltip title={toAddress}>{formatHash(toAddress)}</Tooltip>
    //     </TransactionHashWrapper>
    //   ),
    // },
    {
      title: 'Value',
      dataIndex: 'usdTotal',
      key: 'usdTotal',
      filters: [...new Set(Array.from(formattedTransactions, ({ symbol }) => symbol))].map(
        symbol => ({
          text: symbol,
          value: symbol,
        }),
      ),
      onFilter: (value: string, record: { symbol: string }) => {
        return record?.symbol?.includes(value);
      },
      sorter: (a, b) => {
        return b.usdTotal - a.usdTotal;
      },
      render: (
        _,
        {
          swappedTransaction: swappedTransactions,
          formattedValue,
          sign,
          symbol,
          usdTotal,
          isCurrentAddress,
          value,
        },
      ) => {
        const color = getColor({ isCurrentAddress, usdTotal, value });
        return (
          <TransactionAssetWrapper>
            {swappedTransactions
              ? swappedTransactions?.map((swappedTransaction, index) => {
                  if (+swappedTransaction.value === 0) {
                    return null;
                  }

                  const swColor = getColor({
                    isCurrentAddress:
                      swappedTransaction.toAddress.toLowerCase() ===
                      swappedTransaction.address.toLowerCase(),
                    usdTotal: swappedTransaction.usdTotal,
                    value: swappedTransaction.value,
                  });

                  return (
                    <TransactionAsset key={swappedTransaction.formattedValue + index}>
                      <TransactionAsset style={{ color: swColor }}>
                        <Tooltip title={swappedTransaction?.formattedValue}>
                          <div style={{ display: 'flex', gap: '5px' }}>
                            <span>
                              {swappedTransaction?.formattedValue > 0
                                ? swappedTransaction.sign
                                : ''}
                              {parseFloat(swappedTransaction?.formattedValue || 0).toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 4,
                                },
                              )}
                            </span>
                            <strong>{swappedTransaction.symbol}</strong>
                          </div>
                          <UsdTotal>
                            $
                            {swappedTransaction.usdTotal?.toLocaleString(undefined, {
                              maximumFractionDigits: 4,
                            })}
                          </UsdTotal>
                        </Tooltip>
                      </TransactionAsset>
                    </TransactionAsset>
                  );
                })
              : null}
            {+value !== 0 ? (
              <TransactionAsset
                style={{
                  color,
                }}
              >
                <Tooltip title={formattedValue}>
                  {formattedValue > 0 ? sign : ''}
                  <span>
                    {parseFloat(formattedValue || 0).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                    })}
                  </span>{' '}
                  <strong>{symbol}</strong>
                  <UsdTotal>
                    $
                    {(usdTotal || 0).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                    })}
                  </UsdTotal>
                </Tooltip>
              </TransactionAsset>
            ) : null}
          </TransactionAssetWrapper>
        );
      },
    },
    {
      title: 'Network fee',
      dataIndex: 'usdFee',
      key: 'usdFee',
      sorter: (a, b) => {
        return b.usdFee - a.usdFee;
      },
      render: (_, { fee, feeValue, feeCurrency, usdFee, transactionCategory }) =>
        transactionCategory === 'receive' ? null : (
          <Tooltip title={fee}>
            <div style={{ fontSize: '11px' }}>
              {feeValue} ${feeCurrency}
            </div>
            <UsdTotal>${usdFee?.toFixed(2)}</UsdTotal>
          </Tooltip>
        ),
    },
  ];

  const networks = new Set(Array.from(formattedTransactions, ({ chainName }) => chainName));

  return (
    <div>
      <NetworksWrapper>
        <NetworksTitle>Networks: </NetworksTitle>
        <NetworkList>
          {[...networks].map(network => (
            <span key={network}>{network}</span>
          ))}
        </NetworkList>
      </NetworksWrapper>
      <TransactionsActions>
        <TransactionFiltersWrapper style={{ margin: 0, width: 'initial' }}>
          <TransactionFilterButton
            type={transactionTypeFilter === 'all' ? 'primary' : undefined}
            onClick={() => setTransactionTypeFilter('all')}
          >
            All
          </TransactionFilterButton>
          <TransactionFilterButton
            type={transactionTypeFilter === 'buy' ? 'primary' : undefined}
            onClick={() => setTransactionTypeFilter('buy')}
          >
            Buy
          </TransactionFilterButton>
          <TransactionFilterButton
            type={transactionTypeFilter === 'sell' ? 'primary' : undefined}
            onClick={() => setTransactionTypeFilter('sell')}
          >
            Sell
          </TransactionFilterButton>
        </TransactionFiltersWrapper>
        <Input
          style={{ maxWidth: 390 }}
          placeholder="Filter by hash/address/token"
          value={inputValue}
          onChange={({ target }) => {
            setInputValue(target.value);
          }}
        />
        <DateRangePickerStyled>
          <DateRangePicker
            small={true}
            isOutsideRange={day => day.isAfter(moment())}
            startDateId="start_date"
            endDateId="end_date"
            startDate={startDate}
            endDate={endDate}
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          />
        </DateRangePickerStyled>
        <TransactionActions>
          <Tooltip title="Download transactions ">
            <FileExcelTwoTone
              style={{ fontSize: 25 }}
              onClick={() => {
                downloadCSV(
                  transactionsToCsv(
                    formattedTransactions.filter(({ scam }) => (filterScam ? !scam : true)),
                  ),
                );
              }}
            />
          </Tooltip>
          <Space align="center">
            Filter Scam
            <Switch
              checked={filterScam}
              prefixCls="filterScam"
              title="filterScam"
              onChange={value => {
                setFilterScam(value);
              }}
            />
          </Space>
        </TransactionActions>
      </TransactionsActions>
      <Table
        scroll={{ x: 1154 }}
        rowClassName={record => (record.scam ? 'scam' : '')}
        rowKey={record => record.hash + record.address}
        columns={columns}
        dataSource={formattedTransactions}
      />
    </div>
  );
};
