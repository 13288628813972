import { TabTypes } from 'shared/components/Assets/AssetsSummaryTable/Tabs.enum';
import { TabItem, Tabs } from 'shared/components/Tabs/Tabs';

interface Props {
  activeTab: TabTypes;
  onSetActiveTab: (value: TabTypes) => void;
  isCalculating: boolean;
  allTotalRecordsLength: number;
}
export const SummaryTableTabs = ({
  onSetActiveTab,
  activeTab,
  isCalculating,
  allTotalRecordsLength = 0,
}: Props) => {
  return (
    <Tabs>
      <TabItem
        onClick={() => onSetActiveTab(TabTypes.Assets)}
        active={TabTypes.Assets === activeTab}
      >
        Assets
      </TabItem>
      <TabItem
        onClick={() => !isCalculating && onSetActiveTab(TabTypes.HeatMap)}
        active={TabTypes.HeatMap === activeTab}
      >
        HeatMap
      </TabItem>
      {allTotalRecordsLength > 0 ? (
        <>
          <TabItem
            onClick={() => onSetActiveTab(TabTypes.Chart)}
            active={TabTypes.Chart === activeTab}
          >
            Chart
          </TabItem>
          <TabItem
            onClick={() => onSetActiveTab(TabTypes.History)}
            active={TabTypes.History === activeTab}
          >
            History
          </TabItem>
          <TabItem
            onClick={() => onSetActiveTab(TabTypes.Pivot)}
            active={TabTypes.Pivot === activeTab}
          >
            Pivot
          </TabItem>
          <TabItem
            onClick={() => onSetActiveTab(TabTypes.Transactions)}
            active={TabTypes.Transactions === activeTab}
          >
            Transactions
          </TabItem>
        </>
      ) : null}
    </Tabs>
  );
};
