import { AreaChartOutlined } from '@ant-design/icons';
import { Image, Space, Typography } from 'antd';

import { AccountName } from 'shared/components/Assets/AssetsSummaryTable/AccountName';
import { CoingeckoLink } from 'shared/components/Assets/AssetsTable/columns';
import { DayChangeCell } from 'shared/components/Assets/AssetsTable/DayChangeCell';
import { prettyAccountType } from 'shared/components/Exchages/ExchangeIcon';
import { NetworkIcon } from 'shared/components/Networks/NetworkIcon';
import { TokenCell } from 'shared/components/Tokens/TokenCell';
import { formatUsd } from 'shared/helpers/formatUsd';
import { getBalance } from 'shared/helpers/getBalance';
import { getIconSrc } from 'shared/helpers/url';

import type { ColumnsType } from 'antd/es/table';
import type { AccountSummary, SummaryToken } from 'shared/components/Summary/Summary.interfaces';

function toBigInt(x: number | bigint) {
  try {
    return BigInt(x); // dont use == because 7 == 7n but 7 !== 7n
  } catch (error) {
    return x; // conversion to BigInt failed, surely it is not a BigInt
  }
}

export const assetsColumns = ({ currency, isDarkTheme }): ColumnsType<AccountSummary> => [
  {
    title: 'Token',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (_, { token, name, logo_url, accountType, coingecko_id, address }) => {
      return (
        <TokenCell
          token={token}
          name={name}
          logo_url={logo_url}
          accountType={accountType}
          coingeckoId={coingecko_id}
          address={address}
        />
      );
    },
    sorter: ({ name }, b) => {
      if (name < b.name) return -1;
      if (name > b.name) return 1;
      return 0;
    },
    showSorterTooltip: true,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    render: (_, { amount = 0 }) => Number(amount).toFixed(4),
    sorter: ({ amount }, b) => amount - b.amount,
    ellipsis: true,
    responsive: ['sm'],
  },
  {
    title: `Price ${currency}`,
    dataIndex: 'priceUsd',
    key: 'priceUsd',
    align: 'right',
    render: (_, { priceUsd = 0 }) => parseFloat(Number(priceUsd).toFixed(2)).toLocaleString(),
    sorter: ({ priceUsd }, b) => priceUsd - b.priceUsd,
    responsive: ['sm'],
  },
  {
    title: `Summary ${currency}`,
    dataIndex: 'summaryUsd',
    key: 'summaryUsd',
    align: 'right',
    render: (_, { summaryUsd }: any) =>
      `${parseFloat(summaryUsd.toFixed(2)).toLocaleString()} ${currency}`,
    defaultSortOrder: 'descend',
    sorter: ({ summaryUsd }: any, b: any) => summaryUsd - b.summaryUsd,
  },
  {
    title: 'Changes (24h)',
    key: 'day_change_percent_usd',
    align: 'right',
    sorter: ({ day_change_percent_usd }, b) => day_change_percent_usd - b.day_change_percent_usd,
    render: (_: any, { day_change_percent_usd }) =>
      day_change_percent_usd ? (
        <DayChangeCell percent={+day_change_percent_usd.toFixed(2)} />
      ) : null,
  },
  {
    width: '100px',
    title: 'Project info',
    key: 'projectInfo',
    align: 'right',
    responsive: ['sm'],
    render: (_: any, { coingecko_id, day_change_percent_usd }) =>
      (day_change_percent_usd && coingecko_id && !coingecko_id?.includes('custom-id-')) ||
      coingecko_id?.includes('usd-coin') ||
      coingecko_id?.includes('tether') ? (
        <CoingeckoLink target="_blank" href={`https://www.coingecko.com/en/coins/${coingecko_id}`}>
          <AreaChartOutlined style={{ color: isDarkTheme ? '#728faa' : '' }} />
        </CoingeckoLink>
      ) : null,
  },
];

export const protocolColumns = ({ currency }): ColumnsType<AccountSummary> => [
  {
    title: 'Protocol',
    dataIndex: 'protocol',
    key: 'protocol',
    render: (_, { protocol, logo_url, coingecko_id, address }) => (
      <TokenCell
        token={protocol}
        name={protocol}
        logo_url={logo_url}
        accountType="wallet_protocol"
        coingeckoId={coingecko_id}
        address={address}
      />
    ),
    sorter: ({ protocol }, b) => {
      if (protocol < b.protocol) return -1;
      if (protocol > b.protocol) return 1;
      return 0;
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (_, { type }) => type,
    sorter: ({ type = '' }, b) => {
      if (type && b.type) {
        if (type < b.type) return -1;
        if (type > b.type) return 1;
      }
      return 0;
    },
  },
  {
    title: `Balance ${currency}`,
    dataIndex: 'summaryUsd',
    key: 'summaryUsd',
    align: 'left',
    sorter: ({ summaryUsd }, b) => {
      return summaryUsd - b.summaryUsd;
    },
    render: (_, { summaryUsd }) => summaryUsd,
    responsive: ['sm'],
  },
  {
    title: 'Total',
    dataIndex: 'summaryBalanceUsd',
    key: 'summaryBalanceUsd',
    align: 'right',
    render: (_, { summaryBalanceUsd }: any) => `${summaryBalanceUsd} ${currency}`,
    defaultSortOrder: 'descend',
    sorter: ({ summaryBalanceUsd }: any, b: any) => summaryBalanceUsd - b.summaryBalanceUsd,
    showSorterTooltip: {
      title: 'Balance + Rewards',
    },
  },
];

export const accountsColumns: ColumnsType<any> = [
  {
    title: 'Account',
    dataIndex: 'accountName',
    key: 'id',
    align: 'left',
    ellipsis: true,
    render: (_, { accountName, network, logo_url, accountType, id, connectionId }) => (
      <AccountName connectionId={connectionId} key={id}>
        <>
          {network || accountType ? (
            <NetworkIcon
              network={network}
              style={{ width: '20px', display: 'flex', borderRadius: '50%' }}
              logo_url={logo_url}
              accountType={accountType}
            />
          ) : null}
          <Typography.Text ellipsis>{accountName}</Typography.Text>
        </>
      </AccountName>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'accountType',
    key: 'id',
    align: 'left',
    ellipsis: true,
    render: (_, { accountType }) => (
      <Typography.Text ellipsis>{prettyAccountType(accountType)}</Typography.Text>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'left',
    render: (_, { amount }) => Number(amount).toFixed(4),
    responsive: ['sm'],
  },
  {
    title: 'Total USD',
    dataIndex: 'totalUsd',
    key: 'totalUsd',
    align: 'right',
    render: (_, { totalUsd = 0 }) => formatUsd(totalUsd),
  },
];

export const accountsProtocolColumns: ColumnsType<any> = [
  {
    title: 'Account',
    dataIndex: 'key',
    key: 'key',
    align: 'center',
    ellipsis: true,
    render: (_, { accountName, network, connectionId }) => (
      <AccountName connectionId={connectionId}>
        <>
          {network ? (
            <Space>
              <Image width={20} preview={false} src={getIconSrc(network)} />
              {accountName}
            </Space>
          ) : (
            accountName
          )}
        </>
      </AccountName>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (_, { type }) => type,
  },
  {
    title: 'Token Amount',
    key: 'balance',
    ellipsis: true,
    render: (_, { supply_token_list }) => (
      <>
        {[...(supply_token_list || [])]
          .sort((a, b) => b.amount - a.amount)
          // .filter(({ amount }) => amount > 0)
          .map(({ optimized_symbol, amount }) => (
            <div key={optimized_symbol}>
              {Number(amount).toFixed(2)} {optimized_symbol}{' '}
            </div>
          ))}
      </>
    ),
  },
  {
    title: 'Rewards',
    dataIndex: 'rewards',
    key: 'rewards',
    align: 'left',
    render: (_, { rewards }: { rewards: SummaryToken[] }) =>
      rewards?.map(({ amount, name, price }, index) => {
        return (
          <span key={`rewards-${index}`}>
            {amount.toFixed(5)} {name} ($
            {Math.floor(amount * price).toFixed(0)})
            <br />
          </span>
        );
      }),
    responsive: ['sm'],
  },
  {
    title: 'Borrowed',
    dataIndex: 'borrowed',
    key: 'borrowed',
    align: 'left',
    render: (_, { borrowed }: { borrowed: SummaryToken[] }) =>
      borrowed?.map(({ amount, name, price }, index) => {
        return (
          <span key={`borrowed-${index}`}>
            {amount.toFixed(5)} ($
            {Math.floor(amount * price).toFixed(0)}) {name}
            <br />
          </span>
        );
      }),
    responsive: ['sm'],
  },
  {
    title: 'Health rate',
    dataIndex: 'health',
    key: 'health',
    align: 'left',
    render: (_, { health_rate }) => {
      if (health_rate === null) {
        return null;
      }
      return typeof toBigInt(+health_rate) === 'bigint' || +health_rate > 100
        ? `>100`
        : health_rate?.toString()?.slice(0, 4);
    },
    responsive: ['sm'],
  },
  {
    title: 'Total USD',
    dataIndex: 'totalUsd',
    key: 'totalUsd',
    align: 'right',
    render: (_, { totalUsd, rewards }) => formatUsd(getBalance(totalUsd, [], rewards)),
  },
];
