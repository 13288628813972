import { useRecoilState, useRecoilValue } from 'recoil';

import { ChartComponent } from 'shared/components/Charts/AccountsTotalCharts';
import { fierblockAccountSelector } from 'shared/recoil/atoms/accountCards';
import { summaryAssetsTotal } from 'shared/recoil/selectors/summaryAssets';
import { userSelector } from 'shared/recoil/selectors/user';

export const SummaryChart = ({ data, isLoading }) => {
  const user = useRecoilValue(userSelector);
  const { totalUsd } = useRecoilValue(summaryAssetsTotal('summary'));
  const [fireblockAccount] = useRecoilState(fierblockAccountSelector);

  const filteredData = data
    .filter(({ portfolioType, accountId }) =>
      fireblockAccount ? accountId === fireblockAccount.accountId : portfolioType === 'user',
    )
    .sort((a, b) => a.date - b.date);
  const dataProps = isLoading
    ? []
    : [
        ...filteredData,
        {
          id: Date.now(),
          type: 'wallet',
          tokens: 0,
          protocols: 0,
          summary: totalUsd,
          date: 'live_point',
          userId: user?.id,
        },
      ];
  return <ChartComponent data={dataProps} isLoading={isLoading} />;
};
