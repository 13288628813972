import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { AccountHistory } from 'shared/components/Accounts/AccountHistory/AccountHistory';
import { GET_WALLET_TOTALS_BY_USER_ID } from 'shared/components/Accounts/Accounts.graphql';
import { PivotTable } from 'shared/components/Accounts/components/PivotTable';
import { AccountSummaryTableContent } from 'shared/components/Assets/AssetsSummaryTable/AccountSummaryTableContent';
import { SummaryTransactions } from 'shared/components/Assets/AssetsSummaryTable/SummaryTransactions';
import { TabTypes } from 'shared/components/Assets/AssetsSummaryTable/Tabs.enum';
import { TotalAssets } from 'shared/components/Assets/AssetsSummaryTable/TotalAssets';
import { Actions } from 'shared/components/Assets/AssetsSummaryTable/сomponents/Actions';
import { SummaryTableTabs } from 'shared/components/Assets/AssetsSummaryTable/сomponents/SummaryTableTabs';
import { HeatMap } from 'shared/components/HeatMap/HeatMap';
import {
  cryptoTickersAtom,
  currencyAtom,
  selectedCurrencyAtom,
} from 'shared/recoil/atoms/currency';
import {
  summaryAssetsTableSelector,
  summaryAssetsTotal,
} from 'shared/recoil/selectors/summaryAssets';
import { userSelector } from 'shared/recoil/selectors/user';
import {
  calculatePercentageChangeInCurrency,
  calculateRelativeChange,
  coinGeckoIds,
  convertCryptoPrice,
} from 'shared/utils/currencyConverter';

import { SummaryChart } from './сomponents/SummaryChart';

export const AssetSummaryTable = styled(Card)`
  .ant-card-head {
    padding: 0;
  }

  .ant-card-extra {
    width: 100%;
  }
`;

export const SummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  @media (max-width: 520px) {
    flex-direction: column;
    align-items: baseline;
  }
`;

interface Props {
  showAllTokens: boolean;
  onSetShowAllTokens: (value: boolean) => void;
  showUnverifiedTokens: boolean;
  onSetShowUnverifiedTokens: (value: boolean) => void;
}

export const AssetsSummaryTable = ({
  showAllTokens,
  onSetShowAllTokens,
  showUnverifiedTokens,
  onSetShowUnverifiedTokens,
}: Props) => {
  const [cryptoTickerList] = useRecoilState(cryptoTickersAtom);
  const [selectedCurrency] = useRecoilState(selectedCurrencyAtom);
  const [exchangeRates] = useRecoilState(currencyAtom);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(TabTypes.Assets);
  const [open, setOpen] = useState(false);
  const { control, watch } = useForm<{
    showAllTokensLimits: string | number;
  }>({
    defaultValues: {
      showAllTokensLimits: localStorage.getItem('showAllTokensLimits') || 10,
    },
  });
  const user = useRecoilValue(userSelector);
  const { data: totals } = useQuery(GET_WALLET_TOTALS_BY_USER_ID, {
    skip: !user?.id,
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: user?.id,
    },
  });
  const handleCancel = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const magicNumber = watch('showAllTokensLimits');

  const assets = useRecoilValue(summaryAssetsTableSelector);
  const { tokens, protocols } = assets;
  const { isCalculating } = useRecoilValue(summaryAssetsTotal('summary'));

  const groupedTokens = [...new Set(Array.from(tokens, ({ name, token }) => `${name}-${token}`))];

  const formattedTokens = groupedTokens.reduce((acc, tokenName) => {
    const tokenObj = tokens
      .filter(
        tokenData =>
          `${tokenData.name?.toLowerCase()}-${tokenData.token?.toLowerCase()}` ===
          tokenName?.toLowerCase(),
      )
      .reduce((acc, tokenDataObj) => {
        return {
          ...tokenDataObj,
          day_change_percent_usd: tokenDataObj?.day_change_percent_usd
            ? tokenDataObj?.day_change_percent_usd
            : acc.day_change_percent_usd,
          summaryUsd: tokenDataObj.summaryUsd + (acc.summaryUsd || 0),
          amount: tokenDataObj.amount + (acc.amount || 0),
          summaryBalanceUsd: tokenDataObj.summaryBalanceUsd + (acc.summaryBalanceUsd || 0),
          accountsMap: acc?.accountsMap?.concat([...Object.values(tokenDataObj.accountsMap)]) || [
            ...Object.values(tokenDataObj.accountsMap),
          ],
        };
      }, {});
    return [...acc, tokenObj];
  }, []) as unknown as [];

  const MAGIC_NUMBER = localStorage.getItem('showAllTokensLimits') || 10;

  const filteredTokens = (
    showAllTokens
      ? formattedTokens
      : formattedTokens.filter(({ accountsMap }) => {
          const totalUsd = (accountsMap as unknown as []).reduce(
            (acc, { totalUsd }) => acc + totalUsd,
            0,
          );
          return totalUsd > MAGIC_NUMBER;
        })
  )
    .filter(({ is_verified }) => showUnverifiedTokens || is_verified)
    .map(({ token, priceUsd, amount, day_change_percent_usd, ...rest }) => {
      const price = convertCryptoPrice({
        cryptoToUSD: priceUsd,
        exchangeRates,
        targetCurrency: selectedCurrency,
        cryptoRates: cryptoTickerList,
      }).value;

      const targetRate = exchangeRates.find(rate => {
        return selectedCurrency === rate.currency;
      });

      const targetCryptoRate = cryptoTickerList.find(rate => {
        return selectedCurrency === coinGeckoIds[rate.name];
      });

      return {
        ...rest,
        token,
        day_change_percent_usd: targetRate
          ? calculatePercentageChangeInCurrency({
              cryptoPriceTodayUSD: priceUsd,
              percentageChangeUSD: day_change_percent_usd,
              rateToday: targetRate.value,
              rateYesterday: targetRate.prevDayValue,
            })
          : calculateRelativeChange(
              { priceTodayUSD: priceUsd, percentageChangeUSD: day_change_percent_usd },
              {
                priceTodayUSD: targetCryptoRate?.price,
                percentageChangeUSD: targetCryptoRate?.change_24h,
              },
            ),
        priceUsd: price,
        summaryBalanceUsd: price * amount,
        summaryUsd: price * amount,
        amount,
      };
    });

  const filteredProtocols = (
    showAllTokens
      ? protocols
      : protocols.filter(({ summaryUsd }) => Number(summaryUsd) > MAGIC_NUMBER)
  ).map(({ token, summaryUsd, summaryBalanceUsd, day_change_percent_usd, ...rest }) => {
    const currencyValue =
      exchangeRates.find(({ currency }) => currency === selectedCurrency)?.value || 1;

    const cryptoCurrencyValue = cryptoTickerList.find(
      rate => selectedCurrency === coinGeckoIds[rate.name],
    )?.price;
    return {
      ...rest,
      token,
      day_change_percent_usd,
      summaryBalanceUsd: parseFloat(
        (cryptoCurrencyValue
          ? summaryBalanceUsd / cryptoCurrencyValue
          : summaryBalanceUsd * currencyValue
        ).toFixed(2),
      ).toLocaleString(),
      summaryUsd: parseFloat(
        (cryptoCurrencyValue
          ? summaryUsd / cryptoCurrencyValue
          : summaryUsd * currencyValue
        ).toFixed(2),
      ).toLocaleString(),
    };
  });

  const handleSetActiveTab = (tab: TabTypes) => {
    const url = new URL(window.location.href);
    url.searchParams.set('activeTab', tab);
    navigate(url.pathname + url.search);
    setActiveTab(tab);
  };

  const isAssetsTab = TabTypes.Assets === activeTab;
  const isHeatMapTab = TabTypes.HeatMap === activeTab;
  const isChartTab = TabTypes.Chart === activeTab;
  const isHistoryTab = TabTypes.History === activeTab;
  const isPivotTab = TabTypes.Pivot === activeTab;
  const isTransactions = TabTypes.Transactions === activeTab;

  useEffect(() => {
    const url = new URL(window.location.href);
    const activeTab = url.searchParams.get('activeTab') as TabTypes;
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, []);

  const heatMapProtocols = filteredProtocols.map(({ summaryUsd, ...rest }) => ({
    ...rest,
    totalUsd: summaryUsd,
  }));

  const protocolTokens = heatMapProtocols
    .map(({ accountsMap }) => Object.values(accountsMap))
    .flat()
    .map(({ supply_token_list }) => [...(supply_token_list || [])])
    .flat()
    .map(({ amount, price, symbol, ...rest }) => ({
      ...rest,
      totalUsd: +amount * +price,
      token: symbol,
      amount,
      price,
      symbol,
    }));

  const heatMapTokens = Object.values(
    filteredTokens.map(({ summaryUsd, ...rest }) => ({
      ...rest,
      totalUsd: summaryUsd,
    })),
  );

  const csvData = {
    protocols: heatMapProtocols,
    tokens: heatMapTokens,
  };

  return (
    <>
      <AssetSummaryTable
        style={{ borderRadius: 0 }}
        extra={
          <div>
            <SummaryTableTabs
              activeTab={activeTab}
              onSetActiveTab={handleSetActiveTab}
              isCalculating={isCalculating}
              allTotalRecordsLength={totals?.walletTotalsByUserId?.allTotalRecords?.length}
            />
            <SummaryWrapper>
              <TotalAssets type="summary">Total {selectedCurrency}: </TotalAssets>
              <Actions
                control={control}
                magicNumber={+magicNumber}
                onCancel={handleCancel}
                csvData={csvData}
                onOpen={handleOpen}
                onSetShowAllTokens={onSetShowAllTokens}
                onSetShowUnverifiedTokens={onSetShowUnverifiedTokens}
                open={open}
                showAllTokens={showAllTokens}
                showUnverifiedTokens={showUnverifiedTokens}
              />
            </SummaryWrapper>
          </div>
        }
      >
        {isAssetsTab ? (
          <AccountSummaryTableContent
            currency={selectedCurrency}
            filteredProtocols={filteredProtocols}
            filteredTokens={filteredTokens}
            isProtocols={!!protocols.length}
            isCalculating={isCalculating}
            showAllTokens={showAllTokens}
          />
        ) : null}
        {isHeatMapTab ? (
          <HeatMap
            keyMap="summary"
            accountSummaryAssets={{
              tokens: heatMapTokens,
              protocols: heatMapProtocols,
              protocolTokens: Object.values(
                [...heatMapTokens, ...protocolTokens].reduce((acc, item) => {
                  const key = item.coingecko_id || item.token;
                  if (!acc[key]) {
                    acc[key] = { ...item, totalUsd: 0, amount: 0 };
                  }
                  acc[key].totalUsd += item.totalUsd;
                  acc[key].amount += item.amount;
                  return acc;
                }, {}),
              ),
            }}
          />
        ) : null}
        {isChartTab && totals?.walletTotalsByUserId?.allTotalRecords?.length ? (
          <SummaryChart
            data={totals?.walletTotalsByUserId?.allTotalRecords || []}
            isLoading={isCalculating}
          />
        ) : null}
        {isHistoryTab && totals?.walletTotalsByUserId?.allTotalRecords?.length ? (
          <AccountHistory />
        ) : null}
        {isPivotTab && formattedTokens.length ? (
          <PivotTable formattedTokens={formattedTokens} protocols={protocols} />
        ) : null}
        {isTransactions ? <SummaryTransactions /> : null}
      </AssetSummaryTable>
    </>
  );
};
