interface Props {
  percent: number;
}
export const DayChangeCell = ({ percent }: Props) => {
  const positive = percent > 0;

  return (
    <div
      style={{
        color: positive ? 'green' : 'red',
        backgroundColor: positive ? '' : '',
      }}
    >
      {positive ? `+${percent}` : percent}%
    </div>
  );
};
