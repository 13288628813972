import { Button, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { SettingsModal } from 'shared/components/Assets/AssetsSummaryTable/сomponents/SettingsModal';
import DownloadCsv from 'shared/images/download_24.svg';
import DownloadCsvLight from 'shared/images/download_24_light.svg';
import Settings from 'shared/images/icon_settings_24.svg';
import SettingsLight from 'shared/images/icon_settings_24_light.svg';
import { themeAtom } from 'shared/recoil/atoms/theme';
import { combineCSVData, downloadCSV } from 'shared/utils/toCsv';

import type { Control } from 'react-hook-form';

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 520px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const DownloadCsvButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const SwitchesWrapper = styled.div`
  @media (max-width: 520px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledSpace = styled(Space)`
  color: #0d0d0d80;
  font-size: 17px;
  font-family: Cabin, sans-serif;
  font-weight: 300;
`;

interface Props {
  magicNumber: number;
  showAllTokens: boolean;
  showUnverifiedTokens: boolean;
  onOpen: () => void;
  onCancel: () => void;
  control: Control<{
    showAllTokensLimits: string | number;
  }>;
  csvData: any;
  onSetShowUnverifiedTokens: (value: boolean) => void;
  onSetShowAllTokens: (value: boolean) => void;
  open: boolean;
}

export const Actions = ({
  magicNumber,
  showAllTokens,
  onSetShowAllTokens,
  showUnverifiedTokens,
  onOpen,
  onCancel,
  csvData,
  onSetShowUnverifiedTokens,
  control,
  open,
}: Props) => {
  const [appTheme, setTheme] = useRecoilState(themeAtom);

  const isDark = appTheme === 'dark';

  return (
    <ActionsWrapper>
      <SwitchesWrapper>
        <StyledSpace align="center">
          {`Show all tokens < $${magicNumber}`}:
          <Switch checked={showAllTokens} onChange={onSetShowAllTokens} />
        </StyledSpace>
        <StyledSpace align="center">
          Show unverified tokens
          <Switch checked={showUnverifiedTokens} onChange={onSetShowUnverifiedTokens} />
        </StyledSpace>
      </SwitchesWrapper>
      <Button onClick={onOpen} icon={isDark ? <SettingsLight /> : <Settings />} type="link" />
      <DownloadCsvButton
        onClick={() => {
          const data = combineCSVData(csvData);
          downloadCSV(data, `portfolio-${dayjs(new Date().toString()).format('DD-MM-YYYY')}`);
        }}
        icon={isDark ? <DownloadCsvLight /> : <DownloadCsv />}
        type="link"
      />
      <SettingsModal open={open} onCancel={onCancel} magicNumber={+magicNumber} control={control} />
    </ActionsWrapper>
  );
};
